.App {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  width: 291px;
  height: 450px;
  background-color: #bbada0;
  margin: 5px;
  padding: 5px;
  font-family: 'Dosis', sans-serif;
}

.appContent {
  background-color: #d1c6ad;
  margin: 5px;
  padding: 5px;
  border-radius: 4%;
}

.App h1 {
  color: #0b1d51;
  text-align: center;
}

h2 {
  color: #631d76;
}

.navBar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* display: flex;
    flex-direction: row; */
}

.navMenuHome,
.navMenuGlobalSummary,
.navMenuUsSummary,
.navMenuGlobalVaccination,
.navMenuUSVaccination,
.navMenuSearchByCountry {
  background-color: burlywood;
  border-radius: 15%;
  text-align: center;
  margin: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  cursor: pointer;
}

.navMenuHome:hover,
.navMenuGlobalSummary:hover,
.navMenuUsSummary:hover,
.navMenuGlobalVaccination:hover,
.navMenuUSVaccination:hover,
.navMenuSearchByCountry:hover {
  background-color: chocolate;
  transition: background 700ms;
}
.searchForm {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 5px;
  padding: 5px;
}

.countrySearchInputBox {
  border: none;
  padding: 5px;
}

.searchInputSubmitButton {
  cursor: pointer;
  padding: 4px;
}

.data,
.no-data {
  margin: 5px;
  padding: 5px;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
}

/* div { */
/* border: 1px solid black; */
/* margin: 5px;
    padding: 5px; */
/* } */
li {
  list-style-type: none;
  color: #201a23;
}
a:link,
a:visited {
  /* background-color: burlywood; */
  color: purple;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
/* a:hover, a:active {
    background-color: chocolate;
} */
/* a:link {
    color:burlywood;
    text-decoration: none;
}
a:visited {
    color: burlywood;
    text-decoration: none;
}
a:hover {
    color: chocolate;
    text-decoration: underline;
}
a:active {
    color: brown;
    text-decoration: none;
} */
h2 {
  text-align: center;
}

@media (min-width: 450px) {
  .App {
    display: flex;
    justify-content: center;
    width: 600px;
    height: 600px;
  }

  .navBar {
    display: flex;
    flex-direction: column;
  }

  .appHeader {
    width: 250px;
  }

  .appContent h2 {
    font-size: 32px;
  }

  .appContent {
    width: 350px;
    border-radius: 2.5%;
    font-size: 25px;
  }

  .navMenuHome,
  .navMenuGlobalSummary,
  .navMenuUsSummary,
  .navMenuGlobalVaccination,
  .navMenuUSVaccination,
  .navMenuSearchByCountry {
    border-radius: 5%;
    font-size: 20px;
  }

  .App h1 {
    font-size: 40px;
  }
}
